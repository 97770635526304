import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Form } from 'semantic-ui-react';
import { setGoogleLoginCallback, renderGoogleSignInButton } from './helpers/googleAuth';
import TwoFactorAuthentication from './components/two-factor-authentication';

class LoginMain extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            account: '',
            password: '',
            email: '',
            wrongPassword: false,
            forgotPassword: false,
            linkSent: false,
            firstPageLoad: true,
            googleLoginError: false,
            hasNoCompanies: userHasNoCompanies,
            twoFactorAuthentication: false,
            wrong2faCode: false,
            authButtonDisabled: false,
            twoFactorEmail: ''
        };

        this.handleChangeAccount = this.handleChangeAccount.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleLoginClick = this.handleLoginClick.bind(this);
        this.handleForgotPasswordClick = this.handleForgotPasswordClick.bind(this);
        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleForgotPasswordConfirmClick = this.handleForgotPasswordConfirmClick.bind(this);
        this.handleMicrosoftLoginClick = this.handleMicrosoftLoginClick.bind(this);
        this.handleLogoutClick = this.handleLogoutClick.bind(this);
        this.handleTwoFactorSubmit = this.handleTwoFactorSubmit.bind(this);

        setGoogleLoginCallback(rootUrl + 'Login/Google');
    }

    handleChangeAccount(e) {
        this.setState({ account: e.target.value });
    }

    handleChangePassword(e) {
        this.setState({ password: e.target.value })
    }

    handleLoginClick(event) {
        event.preventDefault();

        // send post to server
        fetch(rootUrl + 'Login/Login', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: this.state.account,
                password: btoa(this.state.password)
            })
        })
            .then((res) => { return res.json(); })
            .then(res => {
                if (res.result !== undefined) {
                     // redirect on success
                    if (res.result == 1) {
                        window.location = rootUrl + (redirectUrl === '' ? res.redirect : redirectUrl);
                    } else if (res.result == 2) {
                        this.setState({
                            hasNoCompanies: true
                        });
                    }
                    else if(res.result == 3) {
                        this.setState({
                            twoFactorAuthentication: true,
                            twoFactorEmail: res.email
                        })
                    } else {
                        this.setState({
                            password: '',
                            wrongPassword: true,
                            firstPageLoad: false
                        });
                    }
                }
            });
    }

    handleForgotPasswordClick() {
        this.setState({
            account: '',
            password: '',
            wrongPassword: false,
            forgotPassword: true,
            linkSent: false,
            firstPageLoad: false
        });
    }

    handleBackClick() {
        this.setState({
            account: '',
            password: '',
            wrongPassword: false,
            forgotPassword: false,
            linkSent: false,
            firstPageLoad: false
        });
    }

    handleChangeEmail(event) {
        this.setState({
            email: event.target.value
        });
    }

    handleForgotPasswordConfirmClick(event) {
        event.preventDefault();

        fetch(rootUrl + 'Login/SendRecoveryLink', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: this.state.email
            })
        });
        this.setState({
            account: '',
            password: '',
            wrongPassword: false,
            forgotPassword: false,
            linkSent: true,
            firstPageLoad: false
        });
    }

    handleMicrosoftLoginClick() {
        window.location = rootUrl + 'Login/Microsoft';
    }

    handleLogoutClick() {
        window.location = rootUrl + 'Login/Logout';
    }

    handleTwoFactorSubmit(code) {
        this.setState({
            authButtonDisabled: true
        });
        fetch(rootUrl + 'Login/2fa', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: this.state.account,
                password: btoa(this.state.password),
                userCode: code
            })
        })
        .then((res) => { return res.json(); })
        .then(res => {
            if (res.result !== undefined) {
                 // redirect on success
                if (res.result == 1) {
                    window.location = rootUrl + (redirectUrl === '' ? res.redirect : redirectUrl);
                } else if (res.result == 2) {
                    this.setState({
                        hasNoCompanies: true
                    });
                } else {
                    this.setState({
                        wrong2faCode: true,
                        authButtonDisabled: false
                    });
                }
            }
        })
        .finally(() => {
            this.setState({wrong2faCode:false},() => false)
        });
    }

    render() {
        var content = [];

        if (!this.state.forgotPassword && !this.state.hasNoCompanies && !this.state.twoFactorAuthentication) {
            //Normal login screen
            content.push(
                <h1 className="ui icon header loginHeader textAallonGreen">
                    <div className="content">
                        {wasLogout ? '' : localization.Welcome + ','}
                        <div className="sub header loginSubheader">
                            {wasLogout ? localization.YouHaveBeenSignedOut : localization.PleaseSignIn.toLowerCase() + '.'}
                        </div>
                    </div>
                </h1>
            );
            if (this.state.firstPageLoad) {
                if (invalidRecoveryUrl) {
                    content.push(
                        <div class="loginErrorMessage">{localization.InvalidPasswordRecoveryUrl}</div>
                    );
                }
                if (microsoftLoginError || googleLoginError) {
                    content.push(
                        <div class="loginErrorMessage">{localization.Error}</div>
                    );
                }
                if (microsoftLoginNotLinked) {
                    content.push(
                        <div class="loginErrorMessage">{localization.MicrosoftAccountNotLinked}</div>
                    );
                }
                if (googleLoginNotLinked) {
                    content.push(
                        <div class="loginErrorMessage">{localization.GoogleAccountNotLinked}</div>
                    );
                }
            }
            if (this.state.googleLoginError) {
                content.push(
                    <div class="loginErrorMessage">{localization.Error}</div>
                );
            }
            if (this.state.linkSent) {
                content.push(
                    <div style={{ color: '#ffffff' }}>{localization.PasswordRecoveryLinkSent}</div>
                );
            }
            content.push(
                <Form className="loginForm" onSubmit={this.handleLoginClick}>
                    <Form.Input name={"acc"} label={localization.Username} onChange={this.handleChangeAccount} value={this.state.account} />
                    <Form.Input name={"password"} label={localization.Password} type='password' onChange={this.handleChangePassword} value={this.state.password} />
                    <Form.Button className="loginButton">{localization.SignIn}</Form.Button>
                </Form>
            );
            if (this.state.wrongPassword) {
                content.push(
                    <div style={{ color: 'red' }}>{localization.WrongPassword}</div>
                );
            }
            content.push(
                <a href="#" onClick={this.handleForgotPasswordClick} style={{ display: 'block', marginBottom: '15px', color: '#ffffff' }}>{localization.ForgotPassword}</a>,
                <button class="ui primary button settings-button" style={{ width: '175px', fontSize: '13px' }} onClick={this.handleMicrosoftLoginClick}>
                    <i class="large microsoft icon" style={{ verticalAlign: 'middle' }}></i>
                    <span>{localization.MicrosoftLogin}</span>
                </button>,
                <div style={{ width: '175px', display: 'inline-block', position: 'relative', top: '16px' }}>
                    {renderGoogleSignInButton()}
                </div>
            );

            //Language
            content.push(
                <div className="loginLanguages">
                    <a href={rootUrl + '?lang=fi'}>FI</a>/
                    <a href={rootUrl + '?lang=sv'}>SV</a>/
                    <a href={rootUrl + '?lang=en'}>EN</a>
                </div>
            );
        } else if (this.state.forgotPassword) {
            //Password recovery
            content.push(
                <h1 className="ui icon header loginHeader textAallonGreen">
                    <div className="content">
                        {localization.PasswordRecovery}
                    </div>
                </h1>
            );
            content.push(
                <Form className="loginForm" onSubmit={this.handleForgotPasswordConfirmClick}>
                    <Form.Input name="email" label={localization.Email} onChange={this.handleChangeEmail} value={this.state.email} />
                    <Form.Button className="loginButton">{localization.OrderNewPassword}</Form.Button>
                </Form>
            );
            content.push(
                <div style={{ marginTop: '5px' }}>
                    <a href="#" onClick={this.handleBackClick}>{localization.Back}</a>
                </div>
            );
        } else if(this.state.twoFactorAuthentication) {
            // Two factor authentication
            content.push(
                <TwoFactorAuthentication handleTwoFactorSubmit={this.handleTwoFactorSubmit} actionName="Login" authButtonDisabled={this.state.authButtonDisabled} wrongCode={this.state.wrong2faCode} email={this.state.twoFactorEmail} account={this.state.account} password={this.state.password} />
            )
            if(this.state.wrong2faCode) {
                content.push(
                    <div style={{ color: 'red' }}>{localization.WrongTwoFactorCode}</div>
                )
            }
        } else {
            //User has no companies
            content.push(
                <h1 className="ui icon header loginHeader textAallonGreen">
                    <div className="content">
                        {localization.YouDontHaveAccessToAnyCompanies}
                    </div>
                </h1>
            );
            content.push(
                <Form className="loginForm">
                    <Form.Button onClick={this.handleLogoutClick} className="loginButton">{localization.Logout}</Form.Button>
                </Form>
            );
        }

        return (
            <div style={{ backgroundColor: '#003A5D', height: '100%', width: '100%' }}>
                <div className="logoBox" style={{ height: '145px', width: '100%', paddingTop: '40px' }}>
                    <img className="ui centered image" src="/Content/logo.svg" style={{ height: '100%', width: 'auto' }} />
                </div>
                <div className="ui equal width center middle aligned padded stackable grid" style={{ height: 'calc(100% - 345px)', width: '100%' }}>
                    <div className="row">
                        <div className="column no-padding"></div>
                        <div className="center aligned column">
                            {content}
                        </div>
                        <div className="column no-padding"></div>
                    </div>
                </div>
                <div className="footerBox" style={{ height: '145px' }}>
                </div>
            </div>
        );
    }
}

export default LoginMain

ReactDOM.render(<LoginMain />, document.getElementById('root'));